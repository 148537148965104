/* src/app/bookin/bookin.component.scss */
.avatar {
  width: 100px;
  height: 100px;
}
.content-info {
  align-items: baseline;
}
.get-more {
  margin-bottom: 10px;
}
.btn_load_more {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  width: 100%;
  background-color: #9eade5 !important;
}
/*# sourceMappingURL=bookin.component.css.map */
